'use strict';

angular.module('kljDigitalLibraryApp')
    .config(["$stateProvider", function($stateProvider) {
        $stateProvider
            .state('index.statues', {
                url: '/statues?statues_topic&&statues_full_text&&statues_act&&search_id&&note_id&&saved_tab_id&&otherPage&&savedTabFromOtherPage',
                templateUrl: 'app/statues/statues.html',
                controller: 'StatuesCtrl'
            });
    }]);